body {
    margin: 0;
    padding: 0;
}
#root {
    height: 100vh;
    width: 100vw;
    background-color: #ecf0f1;
}

a {
    text-decoration: none;
}